// Custom global scripts
import LazyLoad from "vanilla-lazyload";
import objectFitImages from "object-fit-images";
import {
  matchHeights,
  awaitSelector,
  watchAwaitSelector,
  hoverVideoStart,
  videoPlayButton,
  videoCloseButton,
  showHideButtons,
  getViewport,
  debounce,
  throttle,
} from "./functions";

// Custom global scripts
jQuery(document).ready(
  (function ($) {
    "use strict";

    // Polyfill: object-fit polyfill
    const ofi = document.querySelectorAll("img.object-fit");
    objectFitImages(ofi);

    // Foundation Init
    $(document).foundation();

    var viewportWidth = getViewport().width;
    var viewportHeight = getViewport().height;

    throttle("scroll", "scroll.optimized");
    throttle("resize", "resize.optimized");
    window.addEventListener(
      "resize.optimized",
      debounce(function () {
        viewportWidth = getViewport().width;
        viewportHeight = getViewport().height;
      }, 300)
    );

    $(window).load(function () {
      $("#nav-main-toggle").on("click", function () {
        $(this).toggleClass("is-active");
      });
    });

    var lazyImages = new LazyLoad({
      elements_selector: "img",
      use_native: true,
    });

    watchAwaitSelector(function () {
      lazyImages.update();
    }, "img");

    jQuery(document).on(
      "click",
      '[data-theme-repeater="card-media-grid.php"] .card__content',
      function (event) {
        let parent = event.target.closest(".card--media-grid");
        if (parent) {
          let id = parent.dataset.open;
          if (id) {
            let modal = new Foundation.Reveal($(`#${id}`));
          }
        }
      }
    );

    watchAwaitSelector(
      function (elements) {
        const sectionHero = document.getElementById("section-hero");
        const playButton = sectionHero.querySelector(".play-button");
        const closeButton = sectionHero.querySelector(".close-button");

        if (sectionHero) {
          sectionHero.addEventListener("mouseover", hoverVideoStart);
        }

        if (playButton) {
          playButton.addEventListener("click", videoPlayButton);
        }

        if (closeButton) {
          closeButton.addEventListener("click", videoCloseButton);
        }
      },
      "#section-hero, #section-hero .play-button, #section-hero .close-button, #section-hero iframe",
      document,
      2500
    );

    // Read more link
    $(".j-article-reveal").click(function (event) {
      event.preventDefault();

      const $revelToggle = $(this);
      const segmentRef = $revelToggle.data("segment-ref");
      const $segmentRef = $(`#${segmentRef}`);

      if ($segmentRef.hasClass("is-active")) {
        $segmentRef.slideUp("slow").removeClass("is-active");
        $revelToggle.text("Read More").removeClass("hollow");
        $("html, body").animate(
          { scrollTop: $segmentRef.closest(".section").offset().top - 10 },
          900,
          "swing"
        );
      } else {
        $segmentRef.slideDown("slow").addClass("is-active");
        $revelToggle.text("Show Less").addClass("hollow");
      }
    });

    // Donate form toggle
    $(".j-advocate-options").on("click", ".button", function (event) {
      event.preventDefault();
      const $button = $(this);
      $button.closest(".button-group").find(".button").removeClass("is-active");
      $button.addClass("is-active");
    });

    // Simple smooth-scroll
    $('a[href*="#"]').on("click", function (event) {
      event.preventDefault();
      const $target = $(this.hash);

      if (
        `${this.hostname}${this.pathname}` ==
        `${window.location.hostname}${window.location.pathname}`
      ) {
        if ($target.offset()) {
          var siteHeaderHeight = 0;
          var stickyFilterHeight = 0;
          var offsetValue = 10;

          if (document.getElementById("section-nav")) {
            var stickyNav = document.getElementById("section-nav");
            stickyFilterHeight = stickyNav.getBoundingClientRect().height;
          }

          if (document.getElementById("site-header")) {
            var siteHeader = document.getElementById("site-header");
            siteHeaderHeight = siteHeader.getBoundingClientRect().height;
          }

          $("html, body").animate(
            {
              scrollTop: $target.offset().top - siteHeaderHeight - offsetValue,
            },
            900,
            "swing"
          );
        }
      } else {
        window.location.href = this.href;
      }
    });

    function simulateClick(element) {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      element.dispatchEvent(event);
    }

    // Front page media hero
    const mediaHero = document.querySelector(".media-hero");
    if (mediaHero) {
      const heroSlots = mediaHero.querySelectorAll(".media-hero__slot");
      const mediaMute = mediaHero.querySelectorAll(".media-hero__mute");
      var videos = [];

      for (let heroSlot of heroSlots) {
        const video = heroSlot.querySelector(".media-hero__video");
        const playButton = heroSlot.querySelector(".play-button");
        const closeButton = heroSlot.querySelector(".close-button");

        if (video && playButton && closeButton) {
          videos.push(video);
          video.addEventListener("play", function (event) {
            playButton.classList.add("hideThis");
            closeButton.classList.remove("hideThis");
          });

          video.addEventListener("pause", function (event) {
            playButton.classList.remove("hideThis");
            closeButton.classList.add("hideThis");
          });

          playButton.addEventListener("click", function () {
            // Start the video
            video.play();
          });

          closeButton.addEventListener("click", function () {
            // Pause video
            video.pause();
          });
        }

        heroSlot.addEventListener("mouseenter", (event) => {
          heroSlot.classList.add("is-active");
          var playVideoPromise = heroSlot.querySelector("video").play();
          if (playVideoPromise !== undefined) {
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
            playVideoPromise
              .catch((error) => {
                console.log("undefined, autoplay prevented?");
                if (playButton) {
                  // Show initial play button
                  playButton.classList.remove("hideThis");
                  // Try to start video play manually, usually fails
                  simulateClick(playButton);
                }
              })
              .then(() => {
                // Auto-play started
              });
          }

          $(heroSlot).find(".card__hidden").slideDown(600);
          heroSlot.classList.add("is-animating");
          setTimeout(() => heroSlot.classList.remove("is-animating"), 550);
        });

        heroSlot.addEventListener("mouseleave", (event) => {
          if (video) {
            video.pause();
          }

          if (heroSlot.classList.contains("is-active")) {
            heroSlot.classList.remove("is-active");

            $(heroSlot).find(".card__hidden").slideUp(600);
            heroSlot.classList.add("is-animating");
            setTimeout(() => heroSlot.classList.remove("is-animating"), 550);
          }
        });
      }

      for (let mute of mediaMute) {
        mute.addEventListener("click", function () {
          for (let video of videos) {
            if (video.muted === false) {
              video.muted = true;

              for (let mute of mediaMute) {
                mute.classList.remove("media-hero__mute--unmuted");
              }
            } else {
              video.muted = false;

              for (let mute of mediaMute) {
                mute.classList.add("media-hero__mute--unmuted");
              }
            }
          }
        });
      }
    }

    // The watch portal login action
    if ($("#action-portal-login").length) {
      $("#action-portal-login").on("click", function (event) {
        event.preventDefault();
        const $button = $(this);
        $button.addClass("is-loading");

        $.ajax({
          url: themeData.ajaxUrl,
          type: "POST",
          dataType: "json",
          data: {
            action: "portal_controller",
            token: $("#portal-login-token").val(),
          },
        })
          .done(function (response) {
            if (response.success && response.data.permalink) {
              window.location.href = response.data.permalink;
            }
          })
          .fail(function () {
            console.log("error");
          })
          .always(function () {
            $button.removeClass("is-loading");
          });
      });
    }

    // Foundation component events
    $(window).on("opened.zf.offcanvas", (event) => {
      $("#nav-main-toggle").addClass("is-active");
    });

    $(window).on("closed.zf.offcanvas", (event) => {
      $("#nav-main-toggle").removeClass("is-active");
    });

    if (document.querySelector(".site-header")) {
      window.addEventListener(
        "scroll.optimized",
        debounce(function () {
          let siteHeader = document.querySelector(".site-header"),
            y = window.pageYOffset,
            siteHeight = window.innerHeight;
          if (y > siteHeight) {
            siteHeader.classList.add("site-header--active");
          } else {
            if (siteHeader.classList.contains("site-header--active")) {
              siteHeader.classList.remove("site-header--active");
            }
          }
        }, 300)
      );

      // Add a class to the body indicating the hero has scrolled out of view
      const filmArchivePage = document.querySelector(
        "body.post-type-archive-film"
      );
      if (filmArchivePage) {
        const siteHeaderBoundingRect = document
          .getElementById("site-header")
          .getBoundingClientRect();
        const filtersSection = document.getElementById("section-filters");
        new Waypoint({
          element: filtersSection,
          handler: function (direction) {
            if ("down" == direction) {
              filtersSection.classList.add("is-minimized");
            } else {
              filtersSection.classList.remove("is-minimized");
            }
          },
          offset: siteHeaderBoundingRect.height,
        });
      }
    }

    const opinionCategories = [].slice.call(
      document.querySelectorAll(".alm-filter--category .alm-filter--radio")
    );
    if (opinionCategories) {
      opinionCategories.forEach(function (element) {
        element.addEventListener("click", function () {
          $("html, body").animate(
            {
              scrollTop: $("#ajax-load-more").offset().top - 100,
            },
            1000,
            "swing"
          );
        });
      });
    }

    const allIssuesFilter = document.querySelector(
      ".post-type-archive-film #alm-filter-2 #radio--2"
    );

    // One of the two buttons or the load more button
    $(
      ".post-type-archive-film #view-shorts-button, .post-type-archive-film #view-videos-button, .post-type-archive-film .alm-load-more-btn.more"
    ).on("click", function (event) {
      let filterTerm = event.target.dataset.value;
      if (filterTerm) {
        var filter = document.querySelector(
          `.post-type-archive-film #alm-filter-1 [data-value='${filterTerm}']`
        );
        if (filter) {
          filter.click();
        }
      }

      // Hide the two buttons
      showHideButtons("hide");
    });

    // Top level filter
    $(".post-type-archive-film #alm-filter-1 a").click(function (e) {
      let type = e.target.dataset.value;

      if (type === "short-films" || type === "videos") {
        // Hide the two buttons
        showHideButtons("hide");
      } else {
        // Show the two buttons
        showHideButtons("show");
      }

      let data = {
        postsPerPage: 6,
        postType: "film, video",
        taxonomy: "location",
        taxonomyTerms: type,
        taxonomyOperator: "IN",
      };

      $(
        ".post-type-archive-film #alm-filter-1 a, .post-type-archive-film #alm-filter-2 a"
      ).removeClass("active");

      e.target.classList.add("active");
      // Set issues "All" to active
      allIssuesFilter.classList.add("active");

      // Update filter
      ajaxloadmore.filter("fade", 250, data);
    });

    // Issues filter clicked
    $(".post-type-archive-film #alm-filter-2 a").click(function (e) {
      let issue = e.target.dataset.value;

      if (
        document.querySelector(".post-type-archive-film #alm-filter-1 a.active")
      ) {
        // Get current top level filter
        var topLevel = document.querySelector(
          ".post-type-archive-film #alm-filter-1 a.active"
        ).dataset.value;
      }

      // Issues "All" filter is selected, and they "short films" and "our videos" filters are not selected
      if (issue === "" && topLevel != "short-films" && topLevel != "videos") {
        // Show buttons
        showHideButtons("show");
      } else {
        // Hide buttons
        showHideButtons("hide");
      }

      let data = {
        postsPerPage: 6,
        postType: "film, video",
        taxonomy: "location:issue",
        taxonomyOperator: "IN:IN",
        taxonomyTerms: `${topLevel}:${issue}`,
      };

      $(".post-type-archive-film #alm-filter-2 a").removeClass("active");
      e.target.classList.add("active");
      ajaxloadmore.filter("fade", 250, data);
    });

    // Hide buttons when "issue" filter is selected (exclude "All" filter)
    $(".post-type-archive-film #alm-filter-2 a")
      .slice(1)
      .on("click", function (event) {
        $(".post-type-archive-film #view-shorts-button").hide();
        $(".post-type-archive-film #view-videos-button").hide();
      });

    // Popup
    if ($("#popup").length) {
      setTimeout(function () {
        $("#popup").foundation("open");
      }, 2000);
    }
  })(jQuery)
);

window.almFilterComplete = function (alm) {
  let hero = document.getElementById("section-hero");
  if (hero) {
    let bottomPosition = hero.scrollHeight - 1;
    window.scrollTo(0, bottomPosition - 1);
  }
};
