import $ from 'jquery';

export function showHideButtons(showOrHide) {
  if (showOrHide === "show") {
    $('#view-shorts-button').show();
    $('#view-videos-button').show();
  } else {
    $('#view-shorts-button').hide();
    $('#view-videos-button').hide();
  }
}

export function getViewport() {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  var dim = {
    width: w,
    height: h
  };
  return dim;
}

export function getScrolledHeight() {
  // Browser compatibilities
  let offset = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
  // Returns number in px (without the 'px' added)
  return offset;
}


export function hoverVideoStart(event) {
  let parent = event.target.closest('#section-hero');
  if (parent) {
    let iframe = parent.querySelector('iframe');
    if (iframe) {
      if (!iframe.src.includes("autoplay=1")) {
        iframe.src += "&autoplay=1";
        iframe.style.opacity = "1";

        let closeButton = parent.querySelector('.close-button');
        let playButton = parent.querySelector('.play-button');

        if (closeButton) {
          closeButton.classList.toggle("hideThis");
        }

        if (playButton) {
          playButton.classList.add("hideThis");
        }
      }
    }
  }
}

export function videoPlayButton(event) {
  let parent = event.target.closest('section');
  if (parent) {
    let iframe = parent.querySelector('iframe');
    if (iframe) {
      if (!iframe.src.includes("autoplay=1")) {
        iframe.src += "&autoplay=1";
        iframe.style.opacity = "1";
      } else {
        iframe.style.opacity = "1";
        iframe.click();
      }

      let closeButton = parent.querySelector('.close-button');
      let playButton = parent.querySelector('.play-button');

      if (closeButton) {
        closeButton.classList.toggle("hideThis");
      }

      if (playButton) {
        playButton.classList.add("hideThis");
      }
    }
  }
}

export function videoCloseButton(event) {
  // Stop video
  let parent = event.target.closest('section');
  let element = event.target;
  // let parent = element.parentNode;
  let iframe = parent.querySelector('iframe');

  if (parent && iframe) {
    if (iframe.src.includes("&autoplay=1")) {
      // Stop iframe and hide it
      iframe.src = iframe.src.replace("&autoplay\=1", "");
      // iframe.style.opacity = "0";
      iframe.click();

      let playButton = parent.querySelector('.play-button');
      if (playButton) {
        // Show play button
        playButton.classList.remove('hideThis');
      }

      // Hide close button
      element.classList.add("hideThis");
      // Remove hover event listener
      parent.removeEventListener('mouseover', hoverVideoStart);
    }
  }
}

export function matchHeights(parentSelector, childSelector) {
  let parents = document.querySelectorAll(parentSelector);
  if (parents.length > 0) {
    // For each parent element
    for (let i = 0; i < parents.length; i++) {
      var highestBox = 0;

      var children = parents[i].querySelectorAll(childSelector);
      if (children.length > 0) {
        // For each child within the parent
        for (let j = 0; j < children.length; j++) {
          if (children[j].getBoundingClientRect().height) {
            highestBox = children[j].getBoundingClientRect().height;
          }
        }

        // Set equal height for all children within this parent
        for (let k = 0; k < children.length; k++) {
          children[k].style.height = highestBox;
        }
      }
    }
  }
}


/**
 * watchAwaitSelector
 * * Uses mutation observer to watch for 'selector' involved in DOM changes (added, removed, class change, content change, etc)
 * @param {Function} callback A callback function to be run whenever DOM changes are detected, can be anonymous or named
 * @param {String} selector Element(s) to look for involved in DOM changes, you can watch multiple selectors by separating via comma '.class1, .class2, #id3'
 * @param {Node} rootNode Node to watch from, typically document
 * @param {Number} fallbackDelay Interval to be used if the browser does not support MutationObserver, value is in milliseconds (2000 = 2 seconds)
 */
export const awaitSelector = (selector, rootNode, fallbackDelay) => new Promise((resolve, reject) => {
  try {
    const root = rootNode || document
    const ObserverClass = MutationObserver || WebKitMutationObserver || null
    const mutationObserverSupported = typeof ObserverClass === 'function'
    let observer
    const stopWatching = () => {
      if (observer) {
        if (mutationObserverSupported) {
          observer.disconnect()
        } else {
          clearInterval(observer)
        }
        observer = null
      }
    }
    const findAndResolveElements = () => {
      const allElements = root.querySelectorAll(selector)
      if (allElements.length === 0) return
      const newElements = []
      const attributeForBypassing = 'data-awaitselector-resolved'
      allElements.forEach((el, i) => {
        if (typeof el[attributeForBypassing] === 'undefined') {
          allElements[i][attributeForBypassing] = ''
          newElements.push(allElements[i])
        }
      })
      if (newElements.length > 0) {
        stopWatching()
        resolve(newElements)
      }
    }
    if (mutationObserverSupported) {
      observer = new ObserverClass(mutationRecords => {
        const nodesWereAdded = mutationRecords.reduce(
          (found, record) => found || (record.addedNodes && record.addedNodes.length > 0),
          false
        )
        if (nodesWereAdded) {
          findAndResolveElements()
        }
      })
      observer.observe(root, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeOldValue: true,
        characterData: true,
        characterDataOldValue: true,
        attributeFilter: ['style', 'className', 'class']
      })
    } else {
      observer = setInterval(findAndResolveElements, fallbackDelay || 250)
    }
    findAndResolveElements()
  } catch (exception) {
    reject(exception)
  }
})

export const watchAwaitSelector = (callback, selector, rootNode, fallbackDelay) => {
  (function awaiter(continueWatching = true) {
    if (continueWatching === false) return
    awaitSelector(selector, rootNode, fallbackDelay)
      .then(callback)
      .then(awaiter)
  }())
}




// Throttle an event and emit a custom event
export function throttle(type, name, obj) {
  obj = obj || window;
  var running = false;
  var func = function () {
    if (running) {
      return;
    }
    running = true;
    requestAnimationFrame(function () {
      obj.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };
  obj.addEventListener(type, func);
};

/*
PURPOSE:
Retrieved from https://davidwalsh.name/javascript-debounce-function
Returns a function, that, as long as it continues to be invoked, will not
be triggered. The function will be called after it stops being called for
N milliseconds. If `immediate` is passed, trigger the function on the
leading edge, instead of the trailing.
*/
export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context, args
    context = this
    args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}