import Player from '@vimeo/player';
import { awaitSelector, watchAwaitSelector } from './functions';


// Note: Since the client DOES have a Pro Vimeo account, we may want to look into using <video> tags:
// https://help.vimeo.com/hc/en-us/articles/224823567-Third-party-players-links
// https://developer.vimeo.com/api/authentication#understanding-the-auth-process

// Which give us a source such as:
// https://player.vimeo.com/external/356672898.hd.mp4?s=52fa6ffad982218d2ae53223c2e7c2010e315d59&profile_id=175
// That way we can use just the .play() and .pause() methods, and not have to use click() or src attribute changes for iframes.



// Initialize reveal modal video players
watchAwaitSelector(function(elements) {
  const revealVideos = document.querySelectorAll( '.reveal--video' );
  if( revealVideos ) {

    for( const revealVideo of revealVideos ) {

      // Default options
      const options = {
        loop: false
      };

      var player = new Player( revealVideo.querySelector( '.reveal__video' ), options );
    }
  }

  jQuery('.reveal--video').on('closed.zf.reveal', function(event) {
    let iframe = event.target.querySelector('iframe');
    // For some reason player.pause and player.play were not working with the modals
    if(iframe) {
      if(iframe.src.includes("&autoplay=1")) {
        // Stop iframe and hide it
        iframe.src = iframe.src.replace("&autoplay\=1", "");
        iframe.click();
      }
    }
    // Fix an overlay that seems to get stuck sometimes
    jQuery('.reveal-overlay').hide();
  });

  jQuery('.reveal--video').on('open.zf.reveal', function(event) {
    let iframe = event.target.querySelector('iframe');
    // For some reason player.pause and player.play were not working with the modals
    if(iframe) {
      if(!iframe.src.includes("&autoplay=1")) {
        iframe.src += "&autoplay=1";
      }
      else {
        iframe.click();
      }
    }

  });
}, '.reveal--video', document, 2500);


// External vimeo play/pause toggle
jQuery(document).on( 'click', '.j-vimeo-toggle', function( event ) {
	const vimeoToggle = this;
	const videoRef = vimeoToggle.dataset.videoRef;
  const card = vimeoToggle.closest('.card');
  var closeButton;
  var playButton;

  if(card) {
    closeButton = card.querySelector('.close-button');
    playButton = card.querySelector('.play-button') || card.querySelector('.card__icon');
  }

  const videoPoster = vimeoToggle.dataset.videoPoster;
  const videoScroll = vimeoToggle.dataset.videoScroll;
  const player = new Player( videoRef );


  jQuery('.' + videoPoster).fadeToggle();

  // if (!videoScroll) {
  //   jQuery('html, body').animate({'scrollTop': jQuery('.' + videoPoster).offset().top - 180}, 900, 'swing');
  // }

  if(closeButton) {
    closeButton.addEventListener('click', function(event) {
      // Hide close button
      closeButton.classList.add('hideThis');
      // Show play icon
      if(!vimeoToggle.dataset.played) {
        if( vimeoToggle.classList.contains( 'media-icon' ) ) {
          vimeoToggle.classList.remove( 'anim' );
        }
      }
      

      player.pause();
      // Show video poster
      // jQuery('.' + videoPoster).fadeIn();
    });
  }


  var playerVideoPromise = player.play();

  if (playerVideoPromise !== undefined) {
    // Auto-play was prevented
    // Show a UI element to let the user manually start playback
    playerVideoPromise.catch(error => {
      player.element.src += "&autoplay=1";
      player.play();
    }).then(() => {
      // Auto-play started
      if(playButton) {
        // Show initial play button
        playButton.classList.add('hideThis');
      }
    });
  }

    // Show hide inline play icon
    player.on( 'play', () => {
      if( vimeoToggle.classList.contains( 'media-icon' ) ) {
        vimeoToggle.classList.add( 'anim' );
      }

      if(closeButton) {
        closeButton.classList.remove('hideThis');
      }
    });


    player.on( 'pause', () => {
      if(!vimeoToggle.dataset.played) {
        if( vimeoToggle.classList.contains( 'media-icon' ) ) {
          vimeoToggle.classList.remove( 'anim' );
        }
      }

      if(closeButton) {
        closeButton.classList.add('hideThis');
      }
    });
    
    
    if(!vimeoToggle.dataset.played) {
      vimeoToggle.dataset.played = "true";
      if(vimeoToggle.classList.contains('media-icon')) {
        vimeoToggle.classList.add('anim');
      }
    }
});


// Ajax Load More complete event
// window.almFilterComplete
window.almComplete = function( alm ) {

	// Make sure filtered vimeo videos are intialized...
	if( 'films_block' === alm.id ) {
		const vimeoVideos = document.querySelectorAll( '.j-vimeo-reinit' );
		for( let vimeoVideo of vimeoVideos ) {
      const videoRef = vimeoVideo.dataset.vimeoId;
        const player = new Player( vimeoVideo );
        if( !vimeoVideo.getElementsByTagName('iframe').length ) {
          const player = new Player( vimeoVideo );
        }
		}
	}
};
