import $ from 'jquery';
import slick from 'slick-carousel-browserify';


// Some useful media breakpoint vars as defined in _foundation_settings.scss.
const breakpoints = {
	medium: 768,
	large: 1024,
	xlarge: 1200,
	xxlarge: 1440
}

// Inline slider init - these sliders declare their config inline using the 'data-slick' attribute.
if( $('.j-slick-inline').length ) {
	$('.j-slick-inline').each( function() {
		$(this).slick();
	} );
}