// Donation Page
jQuery(document).ready(function($) {
	var buttonDonate = $('.j-button-donate');
  if( buttonDonate ) {
    buttonDonate.on( 'click', function(e) {
  		e.preventDefault();
  		var amount 			= $('.j-money-input').val(),
  			frequency  		= $('.j-advocate-options .is-active'),
  			urlValue;

  		if (frequency)
  			urlValue = 'https://3generations.giv.sh/89d5?amount=' + amount + '&interval=' + frequency.data('name');
  		else
  			urlValue = 'https://3generations.giv.sh/89d5?amount=' + amount;

  		//if ( $.trim(amount).match(/^\d+$/) )
  		window.location.replace(urlValue);
  	});
  }
}(jQuery))
