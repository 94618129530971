import $ from 'jquery';

// === Woocommerce functions

// Custom add-to-card controls from single film product
const singleProductControls = $('.wc-control');
if( singleProductControls.length ) {

	$('.wc-control').on('click', function(event) {
		
		event.preventDefault();

		const $button = $(this);
		const $form = $button.closest('form');
		const variationId = $button.data('variation-id');
		const purchaseType = $button.data('variation-purchase-type');

		$form.find('input[name="variation_id"]').val( variationId );
		$form.find('input[name="attribute_pa_purchase-type"]').val( purchaseType );

		const vid = $form.find('input[name="variation_id"]').val();
		const pt = $form.find('input[name="attribute_pa_purchase-type"]').val();
		console.log( `vid: ${vid}, pt: ${pt}` );
		$form.submit();
	});
}